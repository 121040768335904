.creator-form-body {
    font-family: 'Montserrat', sans-serif;
    background-color: #2c3e50;
    margin: 0;
    padding: 0;
    border-radius: 20px;
    display: flex;
    justify-content: center; /* Center the container */
}

.apply-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px; /* Reduced padding for less whitespace */
    height: 100%;
    flex-wrap: wrap; /* Allow wrapping of child elements */
    background-color: #2c3e50;
}

.form-section-creator {
    background-color: #2c3e50;
    padding: 30px; /* Adjusted padding for the form section */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 350px; /* Adjusted width for narrower form */
    margin: 0 20px; /* Center the form with margin */
    flex: 1; /* Allow this section to grow and shrink */
    min-width: 300px; /* Set minimum width for mobile */
}

h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.creator-p p {
    color: #fff;
}

a {
    color: #ff6f00;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

label {
    display: block;
    margin-bottom: 15px;
    font-size: 14px;
}

.creator-form input,
.creator-form textarea,
.creator-form select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #454545;
    border-radius: 5px;
    font-size: 14px;
    background-color: #2b2f3a; /* Dark background color */
    font-family: 'Montserrat', sans-serif;
    color: #fff; /* Text color set to white */
}

/* Keep the same background color on focus */
.creator-form input:focus,
.creator-form textarea:focus,
.creator-form select:focus {
    border-color: #007bff; /* Change the border color when focused */
    outline: none; /* Remove default outline */
    background-color: #2b2f3a; /* Keep the dark background on focus */
}

textarea {
    resize: none;
}

.submit-btn {
    background-color: #ff6f00;
    color: white;
    border: none;
    padding: 15px;
    font-size: 16px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    margin-top: 20px; /* Adjust the value to increase/decrease space above the button */
}

.submit-btn:hover {
    background-color: #ffa45e;
}

/* Step Info Styles */
.info-section {
    width: 300px;
    padding: 20px;
}

ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

.step-item {
    margin-bottom: 20px;
    position: relative;
    padding-left: 30px;
}

.step-number-creator {
    background-color: #ff6f00;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 14px;
    font-weight: bold;
}

.step-item h3 {
    margin: 0 0 5px 0;
}

ul {
    list-style: none;
    padding-left: 10px;
}

ul li {
    font-size: 14px;
}

fieldset {
    border: 1px solid #454545;
    border-radius: 5px;
    padding: 15px;
    margin-top: 20px;
    color: #fff;
}

legend {
    font-size: 16px;
    padding: 0 10px;
    background-color: #2b2f3a;
    border-radius: 5px;
}

label {
    display: flex;
    align-items: center; 
    margin-bottom: 10px;
    font-size: 14px;
    color: #fff;
}

input[type="checkbox"] {
    margin-right: 10px; 
    width: 18px;
    height: 18px;
    cursor: pointer;
}

fieldset label {
    margin-bottom: 8px; 
}

fieldset input[type="checkbox"]:focus {
    outline: 2px solid #ff8c35; 
}

/* Responsive Styles */
@media (max-width: 768px) {
    .apply-container {
        flex-direction: column; /* Stack sections vertically */
        align-items: center; /* Center items */
    }
    
    .form-section-creator {
        margin: 0; /* Remove margin on mobile */
        margin-bottom: 40px; /* Add margin below the form section */
        width: 90%; /* Full width on mobile */
        max-width: 350px; /* Maximum width on mobile */
    }

    .info-section {
        width: 100%; /* Full width on mobile */
    }
}
