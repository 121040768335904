.hero {
    text-align: center;
    padding: 100px 20px;
    background: linear-gradient(to bottom, #dcf7ff, #fff);
  }
  
  .hero-title {
    font-size: 54px;
    font-weight: 800;
    color: #2c3e50;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif; 
  }
  
  .hero-subtitle {
    font-size: 22px;
    margin-bottom: 40px;
    color: #666;
    font-weight: 550;
  }

  .hero-subtitle-2{
    font-size: 22px;
    margin-bottom: 40px;
    color: #000;
    font-weight: 900;
  }
  
  .hero-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .hero-btn {
    padding: 15px 30px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .request-access-btn {
    background-color: #007bff;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }
  
  .join-creators-btn {
    background-color: transparent;
    border: 2px solid #2c3e50;
    color: #2c3e50;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }

  .join-creators-btn:hover {
    background-color: transparent;
    border: 2px solid #5b7289;
    color: #5b7289;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }

  /* Hero.css */

.hero-container {
  width: 100%;
  max-width: 100vw; /* Ensures no overflow */
  padding: 0 15px; /* Optional: Internal padding without external overflow */
  box-sizing: border-box;
  text-align: center;
}

.hero-content {
  margin: 0 auto;
  padding: 0;
  max-width: 100%;
}

  