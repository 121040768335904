/* Import Montserrat font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

/* General styles */
body {
  font-family: 'Montserrat', sans-serif;
}

.cta-section {
  background-color: #f5fbfd;
  text-align: center;
  padding: 50px;
  border-radius: 20px;
  margin: 40px 0;
}

.cta-heading {
  font-size: 2.5rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 40px;
}

.cta-subtext {
  display: flex;
  justify-content: space-around;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 50px;
  color: #333;
}

.subtext-item {
  margin: 0 10px;
  text-align: center;
  color: #000;
  font-size: 24px;
}

 .cta-subtitle {
    font-size: 18px;
    margin-bottom: 40px;
    color: #666;
    font-weight: 550;
    font-size: 24px;
  }

/* CTA Button Styling */
.cta-button-container {
  text-align: center;
}

.cta-button {
  background-color: #007bff;
  color: #ffffff;
  padding: 15px 40px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #6aaaef;
}
