/* Base Styles */
.brand-form-body {
  font-family: 'Montserrat', sans-serif;
  background-color: #eef8ff;
  margin: 0;
  padding: 0;
}

.get-started-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 40px;
  height: 100vh;
  gap: 40px; /* Adds spacing between form and steps on larger screens */
}

/* Form Section */
.form-section {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 14px;
  color: #333;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

label {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
}

.brand-form input, 
.brand-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

textarea {
  resize: none;
}

.submit-btn-brand {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  margin-top: 20px;
}

.submit-btn-brand:hover {
  background-color: #006ae0;
}

/* Step Info Section */
.info-section-brand {
  width: 300px;
  padding: 20px;
}

ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.step-item-brand {
  margin-bottom: 20px;
  position: relative;
  padding-left: 30px;
}

.step-number {
  background-color: #007bff;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
}

.step-item-brand h3 {
  margin: 0 0 5px 0;
}

ul {
  list-style: none;
  padding-left: 10px;
}

ul li {
  font-size: 14px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .get-started-container {
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 20px;
  }

  .form-section {
    width: 100%;
    max-width: 400px;
    margin-right: 0;
    margin-bottom: 20px; /* Space between form and info section */
  }

  .info-section-brand {
    width: 100%;
    max-width: 400px;
  }
}
