@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.dark-students-section-container {
  padding: 40px;
}

.dark-students-section {
  background-color: #2c3e50;
  color: white;
  display: flex;
  padding: 60px 80px;
  justify-content: space-between;
  border-radius: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.students-content {
  max-width: 40%;
}

.students-title {
  font-size: 48px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}

.students-subtitle {
  font-size: 14px;
  color: #ff6f00;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.students-description {
  font-size: 16px;
  margin-bottom: 30px;
  color: #fff;
}

.students-button {
  background-color: #ff6f00;
  color: #ffffff;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.students-button:hover {
  background-color: #68c5ff;
}

.students-cards {
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.students-network-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 20px;
  object-fit: cover;
}

/* Tablet view adjustments (up to 768px) */
@media (max-width: 768px) {
  .dark-students-section {
    padding: 40px 20px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .students-content {
    max-width: 100%;
  }

  .students-title {
    font-size: 36px;
  }

  .students-description {
    font-size: 15px;
  }

  .students-cards {
    max-width: 100%;
    justify-content: center;
    margin-top: 20px;
  }

  .students-network-image {
    max-width: 400px;
  }
}

/* Mobile view adjustments (up to 480px) */
@media (max-width: 480px) {
  .dark-students-section-container {
    padding: 20px;
  }

  .dark-students-section {
    padding: 20px;
  }

  .students-title {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .students-description {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .students-button {
    font-size: 14px;
    padding: 10px 20px;
  }

  .students-cards {
    max-width: 100%;
    justify-content: center;
    margin-top: 20px;
  }

  .students-network-image {
    width: 100%;
    max-width: 100%;
    border-radius: 15px;
  }
}
