.benefits-section {
  background-color: #2c3e50; 
  color: white;
  padding: 40px 0;
  font-family: 'Montserrat', sans-serif;
  
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;  
}

.benefit-item {
  display: flex;
  align-items: center; 
  justify-content: flex-start; 
  width: 60%; 
  max-width: 800px; 
  margin-bottom: 30px;
}

.benefit-icon {
  margin-right: 20px;
}

.circle {
  width: 120px; 
  height: 120px;
  border-radius: 50%; 
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.circle-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.benefit-content {
  text-align: left; 
}

.benefit-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.benefit-description {
  margin-top: 5px;
  font-size: 1rem;
  color: #ccc; 
}
