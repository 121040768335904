.creators-page {
  background-color: #2c3e50; 
  color: white; 
  min-height: 100vh;
  padding: 20px; 
}

.creator-section {
  background-color: #2b2f3a;
  border-radius: 15px; 
  padding: 40px;
  width: 90vw; 
  max-width: 1200px; 
  margin: 20px auto;
  text-align: center;
  color: white; 
}

.creator-section-2 {
  background-color: #2c3e50;
  border-radius: 15px; 
  padding: 40px;
  width: 90vw; 
  max-width: 1200px; 
  margin: 20px auto;
  text-align: center;
  color: white; 
}

.creator-section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.creator-image-placeholder {
  display: block; 
  margin: 0 auto;
  width: 100%;
  max-width: 400px; 
  height: 400px; 
  object-fit: cover; 
  border-radius: 15px;
  margin-bottom: 20px;
}

.creator-section-button {
  background-color: #ff6f00;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Montserrat', sans-serif; 
}

.creator-section-button:hover {
  background-color: #d64531; 
}

.creator-section p {
  font-size: 18px;
  color: #bbb;
  margin-top: 15px;
}
