.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #fff;
}

.services-grid {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.service {
  text-align: center;
  width: 250px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 2rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.service-icon {
  max-width: 180px;
  margin-bottom: 1.5rem;
}

.service-title {
  font-size: 1.25rem;
  color: #2c3e50;
  margin-bottom: 0.75rem;
}

.service-description {
  font-size: 1rem;
  color: #616161;
  font-weight: 500;
}

.cta-button-container {
  text-align: center;
  margin-top: 2rem;
}

.cta-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 20px;
}

.cta-button {
  background-color: #3498db;
  color: #fff;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Montserrat', sans-serif;
}

.cta-button:hover {
  background-color: #2980b9;
}

/* Mobile view adjustments (up to 768px) */
@media (max-width: 768px) {
  .services-grid {
    flex-direction: column; /* Stack the service boxes vertically */
    align-items: center;
  }

  .service {
    width: 100%; /* Make each service box take full width */
    max-width: 320px; /* Optional: limit width for smaller screens */
  }
}
