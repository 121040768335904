/* General footer styles */
.footer {
    background-color: #2c3e50;
    color: #fff;
    padding: 40px 80px; /* Padding around the edges */
    font-family: 'Montserrat', sans-serif;
    border-radius: 0px; /* No rounded edges for the whole footer section */
  }
  
  .footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
    border-bottom: 1px solid #bdc3c7;
  }
  
  .footer-logo img {
    width: 280px;
  }
  
  .newsletter {
    text-align: right;
  }
  
  .newsletter-input {
    background-color: #39526c;
    border: none;
    padding: 10px 20px;
    border-radius: 50px; /* Rounded edges for the input field */
    color: #fff;
    font-size: 1rem;
    width: 200px;
    margin-right: 10px;
  }
  
  .subscribe-button {
    background-color: #bdc3c7;
    border: none;
    border-radius: 50px; /* Rounded edges for the button */
    padding: 10px 20px;
    font-weight: 600;
    color: #000;
    cursor: pointer;
  }
  
  .footer-links {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
  }
  
  .footer-column {
    width: 20%;
  }
  
  .footer-column h4 {
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .footer-column ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-column ul li {
    margin-bottom: 10px;
    color: #ccc;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .footer-column ul li:hover {
    color: #fff;
  }
  
  /* Footer bottom section */
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    border-top: 1px solid #bdc3c7;
  }
  
  .footer-bottom-right {
    display: flex;
    align-items: center;
  }
  
  .language-button {
    background-color: transparent;
    padding: 5px 15px;
    color: #ccc;
    border-radius: 50px; /* Rounded edges for the language button */
    cursor: pointer;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    margin-right: 15px;
  }
  
  .language-button i {
    margin-right: 5px;
  }
  
  .language-button:hover {
    color: #fff;
    border-color: #fff;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .footer {
      padding: 20px 40px; /* Reduce padding on smaller screens */
    }
  
    .footer-top {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .newsletter {
      text-align: left;
      margin-top: 20px;
    }
  
    .footer-links {
      flex-direction: column;
    }
  
    .footer-column {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .footer-bottom {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .footer-bottom-right {
      margin-top: 20px;
    }
  }

  .custom-link {
    color: inherit;       /* Inherit the color from parent element or set a specific color */
    text-decoration: none; /* Remove underline */
}

.custom-link:hover {
    color: inherit;       /* Ensure no color change on hover */
    text-decoration: none; /* Keep underline off on hover */
}

.copyright {
  color: #fff;
}
  