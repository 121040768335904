@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.logo-section {
    padding: 50px 0;
    text-align: center;
  }
  
  .logo-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    font-family: 'Montserrat', sans-serif; 
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    gap: 50px; 
    flex-wrap: wrap;
  }
  
  .logo-item {
    max-width: 150px; 
    object-fit: contain;
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }

  .main-logo {
    max-width: 250px; 
    object-fit: contain;
    opacity: 0.95;
    transition: opacity 0.3s ease;
  }
  
  .logo-item:hover {
    opacity: 1; 
  }
  