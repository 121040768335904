@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: #fff;
    background-color: #2c3e50;
  }
  
  .navbar-logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .navbar-links {
    display: flex;
    gap: 80px;
    list-style: none;
    color: #fff;
    margin-left: 120px;
    width: 50%;
    font-weight: 600;
  }
  
  .navbar-links li a {
    text-decoration: none;
    color: #fff;
  }
  
  .navbar-actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .language-icon {
    font-size: 18px;
  }
  
  .signin {
    text-decoration: none;
    color: #fff;
  }
  
  .request-access {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 17px;
    border: none;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    margin-right: 40px;
    font-weight: 600;
  }
  