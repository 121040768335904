.image-section {
    display: flex;
    justify-content: center;
    padding: 50px 0;
  }
  
  .image-placeholder {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 20px; 
  }
  
  .image-placeholder p {
    font-size: 18px;
    color: #999;
  }
  